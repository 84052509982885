//import lib scripts
var $ = require("jquery");
import "bootstrap";
import "jquery-hoverintent";
import "popper.js";
import "lity/dist/lity.css"
import "lity";
import "slick-carousel";

//import customization scripts
import "./skip-link-focus-fix";
import "./main";
import "./carousel";