(function main($){

    // Smooth scrolling
    $('a[href*=\\#]:not([href=\\#])').on( 'click', function() {
            //console.log('smooth scroll!');
            if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') || location.hostname === this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            smoothScrollTo( target );
        }
    });

    //now scroll to the anchor when loading page
    $(document).ready(function() {
        if (!window.location.hash) { return; }

        var hash = window.location.hash;
        var target = $(hash);
        target = target.length ? target : $('[name=' + hash.slice(1) +']');
        smoothScrollTo(target);
    });

    // Toggle active
    $('[data-toggle-active]').click(function(event) {
        event.preventDefault();
        var $toggleTarget = $( $(this).data('toggle-active') );
        $toggleTarget.toggleClass('active');
    });

    // Tabs toggle
    $('[data-tab-activate]').click(function(event) {
        event.preventDefault();
        var $target = $( $(this).data('tab-activate') );
        $('[data-tab-activate]').removeClass('active');
        $(this).addClass('active');
        var $otherTabs = $( $(this).data('other-tabs') );
        $otherTabs.removeClass('active');
        $target.addClass('active');
    });

    // Nav toggle

    if ('ontouchstart' in window) {
        $('.js-menu-item-toggle').click(function(event) {
            var $this = $(this);
            if ($this.hasClass('active')) {
                $('.js-menu-item-toggle').removeClass('active');
            } else {
                $('.js-menu-item-toggle').removeClass('active');
                $this.addClass('active');
            }
        });
    } else {
        $('.js-menu-item-toggle').hoverIntent(function(event) {
            var $this = $(this);
            if ($this.hasClass('active')) {
                $('.js-menu-item-toggle').removeClass('active');
            } else {
                $('.js-menu-item-toggle').removeClass('active');
                $this.addClass('active');
            }
        }, function(event) {
            $('.js-menu-item-toggle').removeClass('active');
        });

        //on key press enter
        //drop down
        $('.js-menu-item-toggle').on( 'keypress', function(e){
            if(e.which == 13){
                var $this = $(this);
                if ($this.hasClass('active')) {
                    $('.js-menu-item-toggle').removeClass('active');
                } else {
                    $('.js-menu-item-toggle').removeClass('active');
                    $this.addClass('active');
                }
            }
        });
    }

    $('.js-mobile-submenu-toggle').click(function(e) {
       $(this).find('.navbar-mobile-menu-submenu').toggleClass('active');
    });

    $('.js-mobile-submenu-close').click(function(e) {
        $(this).parents('.navbar-mobile-menu-submenu').removeClass('active');
        e.stopPropagation();
    });

    $('.js-hamburger-toggle').click(function(e) {
        e.preventDefault();
        $('body').toggleClass('hamburger-open');
    });

    $('.js-search-toggle').click(function(e) {
       event.preventDefault();
       $('.search-bar').toggleClass('active');
       $('#search-options').find('input:first[type="radio"]').get(0).focus();
    });

    $('.js-search-form').submit(function(e) {
        var $form = $('.js-search-form');
        var $input = $form.find('[type="text"]');
        var defaultAction = $form.data('action');
        var collectionAction = $form.data('collection-action');
        var eventsAction = $form.data('events-action');
        if ($form.find('[name="search-option"]:checked').val() == 'collection') {
            $form.attr('action', collectionAction + encodeURIComponent( $( $input ).val() ) );
            $input.attr('name', 'search');
        } else if ($form.find('[name="search-option"]:checked').val() == 'events') {
            $form.attr('action', eventsAction);
            $input.attr('name', 's');
        } else {
            $form.attr('action', defaultAction);
            $input.attr('name', 's');
        }
    });

    // Equalize heights
    var equalizeChildrenHeight = debounce(function(parentSelector) {
        setTimeout(function(){

            // naive equal heights script
            $(parentSelector).each(function(){
                var highestBox = 0;
                $('.equalize-heights', this).each(function(){
                    $(this).height('auto');
                    if($(this).height() > highestBox) {
                        highestBox = $(this).height();
                    }
                });
                $('.equalize-heights',this).height(highestBox);
            });

        }, 250);
    }, 250);
    equalizeChildrenHeight('.equalize-children');

    // equalize again after page has been have resized
    window.addEventListener('resize', function(){ equalizeChildrenHeight('.equalize-children'); });

    // Debounce http://davidwalsh.name/javascript-debounce-function
    function debounce(func, wait, immediate) {
        var timeout;
        return function() {
            var context = this, args = arguments;
            var later = function() {
                timeout = null;
                if (!immediate) {
                    func.apply(context, args);
                }
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) {
                func.apply(context, args);
            }
        };
    }

    // Smooth Scroll Helper
    function smoothScrollTo($target){
        if ($target.length) {
            var scrollValue = $target.offset().top;
            $('html,body').animate({ scrollTop: scrollValue }, 1000);
            return false;
        }
    }

})(jQuery);