// import Swiper JS
// https://swiperjs.com/

/**
 * swiper defs
 */
import Swiper from "swiper/bundle";

var hero_swiper = new Swiper(".hero-carousel", {
	centeredSlides: true,
	init: false,
	loop: true,
	loopFillGroupWithBlank: false,
	simulateTouch: true,
	slidesPerView: 1,
	spaceBetween: 0,
	// autoplay: {
	//     deley: 5000
	// },
	autoplay: false,

	//for a11y
	keyboard: {
		enabled: true,
		onlyInViewport: false,
		pageUpDown: true,
	},
	// Navigation arrows
	navigation: {
		nextEl: ".hero-swiper-button-next screen-reader-text",
		prevEl: ".hero-swiper-button-prev screen-reader-text",
	},

	// pagination
	pagination: {
		el: ".swiper-pagination",
		clickable: true,
		renderBullet: function (index, className) {
			var slideNbr = index + 1;
			//console.log( this.loopedSlides );
			if (this.params.loop) {
				var slideCount = this.slides.length - this.loopedSlides * 2;
			} else {
				var slideCount = this.slides.length;
			}

			//dont show pagination if there is only one slide
			if( slideCount <= 1 ){
				return "";
			}

			var output = '<span class="' + className + '" ';
			output += 'tabindex="0" role="button" ';
			//output += 'data-bullet-index="' + index + '" ';
			output += 'aria-controls="swiper-card" ';
			//output += 'aria-labelledby="related-post-title-' + index + '" ';
			output += 'aria-label="Go to slide ' + slideNbr + " of " + slideCount + '"';
			output += ">";
			//output += '<button>'
			output += slideNbr;
			//output += '</button>';
			output += "</span>";
			return output;
		},
	},
});

var collection_feature = new Swiper(".collection-feature__slider", {
	centeredSlides: true,
	loop: true,
	loopFillGroupWithBlank: false,
	simulateTouch: true,
	slidesPerView: 1,
	spaceBetween: 0,

	//Navigation arrows
	navigation: {
		nextEl: ".collection-feature-swiper-button-next",
		prevEl: ".collection-feature-swiper-button-prev",
	},

	//for a11y
	keyboard: {
		enabled: true,
		onlyInViewport: false,
		pageUpDown: true,
	},

	// pagination
	pagination: {
		el: ".collection-feature-swiper-pagination",
		clickable: true,
		renderBullet: function (index, className) {
			var slideNbr = index + 1;

			//console.log( this.loopedSlides );
			if (this.params.loop) {
				var slideCount = this.slides.length - this.loopedSlides * 2;
			} else {
				var slideCount = this.slides.length;
			}
			
			//dont show pagination if there is only one slide
			if( slideCount <= 1 ){
				return "";
			}

			var output = '<button class="' + className + '" ';
			output += 'tabindex="0" role="button" ';
			//output += 'data-bullet-index="' + index + '" ';
			output += 'aria-controls="swiper-card" ';
			//output += 'aria-labelledby="related-post-title-' + index + '" ';
			output += 'aria-label="Go to slide ' + slideNbr + " of " + slideCount + '"';
			output += ">";
			//output += '<button>'
			output += '<span class="screen-reader-text">';
			output += slideNbr;
			//output += '</button>';
			output += "</span>";
			output += "</button>";
			return output;
		},
	},
});

document.addEventListener("DOMContentLoaded", function (event) {
	/**
	 * wait for dom so that the object is available
	 * there should be only one
	 */
	if (document.querySelector(".hero-carousel")) {
		//var hero_slides_count = parseInt(hero_swiper.slides.length) - 2;

		hero_swiper.init();
		var heroCarouselAutoplay = true;
		var play_pause_el = document.querySelector(".js-hero-carousel-play-pause");
		play_pause_el.addEventListener("click", function () {
			if (heroCarouselAutoplay) {
				hero_swiper.autoplay.stop();
			} else {
				hero_swiper.autoplay.start();
			}
			heroCarouselAutoplay = !heroCarouselAutoplay;
		});
	}

	//allows multiple per page
	//must wait for the dom to load to get carousel ID
	var publication_carousels = document.querySelectorAll(".publications-carousel__slider");
	publication_carousels.forEach((el) => {
		new Swiper("#publications-carousel--" + el.dataset.id + " .publications-carousel__slider", {
			centeredSlides: true,
			slidesPerView: "auto",
			spaceBetween: 0,
			loop: true,
			loopFillGroupWithBlank: false,

			// Navigation arrows
			navigation: {
				nextEl: "#publications-swiper-button-next-" + el.dataset.id,
				prevEl: "#publications-swiper-button-prev-" + el.dataset.id,
			},
		});
	});
});
